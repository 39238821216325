<!--
<template>
  <Wizard v-bind="$attrs" v-on="inputListeners"/>
</template>
-->
<script>
    import Wizard from './components/Wizard'
    export default Wizard;
    /*
    export default {
        name: 'FCNWizard',
        inheritAttrs: false,
        components: {
            Wizard
        },
        computed: {
            inputListeners: function () {
                var vm = this;
                // `Object.assign` merges objects together to form a new object
                return Object.assign({},
                    // We add all the listeners from the parent
                    this.$listeners,
                    // Then we can add custom listeners or override the
                    // behavior of some listeners.
                    {
                        // This ensures that the component works with v-model
                        input: function (event) {
                            vm.$emit('input', event.target.value)
                        }
                    }
                )
            }
        },
    }
    */
</script>

<style>
  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }
</style>
