<template>
  <div :class="['fcn-wizard-v3', 'wizard-fcn', 'theme-' + theme, submit_width ? 'submit--width-' + submit_width : '', submit_align ? ('submit-align--' + submit_align): '']">
    <s-v-g-icons/>
    <slot name="header"></slot>
    <div v-if="stage == 0">
      <form novalidate style="position:relative; display: block;">
        <div class="loadingbox" v-if="loading"></div>

        <div class="wizard-fcn__row">
          <div class="wizard-fcn__column">
            <div class="wizard-fcn__field">
              <input class="wizard-fcn__textinput" v-model="fields.email" type="email" placeholder="Wpisz adres e-mail" v-on:blur="suggestLogin()" v-on:focus="ga(gacategory, 'Kliknięcie w pole e-mail', 'Wpisz adres e-mail')">
            </div>
            <div role="alert" transition="expand" class="wizard-fcn__error" v-if="submitted && ($v.fields.email.$invalid === true)"><span>wymagany jest poprawny adres e-mail</span></div>
          </div>
          <div class="wizard-fcn__column">
            <div class="wizard-fcn__field">
              <input class="wizard-fcn__textinput loading" v-model="fields.username" type="text" placeholder="Wpisz preferowany login" v-on:blur="suggestLogin()" v-on:focus="ga(gacategory, 'Kliknięcie w pole login', 'Wpisz preferowany login')">
              <div role="alert" transition="expand" class="wizard-fcn__error" v-if="submitted && ($v.fields.username.$invalid === true)">
                <svg width="1em" height="1em">
                  <use xlink:href="#icon-emoji-sad" />
                </svg>
                <span v-if="!$v.fields.username.required">wymagane jest podanie loginu</span>
                <span v-else-if="!$v.fields.username.minLength">Login jest za krótki. Minimalna długość to 3 znaki. Dozwolone znaki to małe litery (bez polskich znaków), cyfry, znaki "-" (minus) i "_" (podkreślenie), które nie mogą wystąpić na początku lub na końcu loginu.</span>
                <span v-else>inny problem</span>
              </div>
            </div>
            <div transition="expand" v-if="username_status.visible">
              <div transition="fade" :class="username_status.class" v-if="username_status.visible">
                  <svg width="1em" height="1em">
                    <use v-bind:xlink:href="username_status.icon" />
                  </svg>
                  <span>{{ username_status.text }} <a v-if="username_status.show_suggestion" tabindex="0" v-on:keydown.enter="setUsername(suggested_username)" v-on:click="setUsername(suggested_username)">{{ suggested_username }}</a></span>
              </div>
            </div>
            <span role="alert" transition="expand" class="wizard-fcn__error" v-if="submitted && $v.fields.username.required && $v.fields.username.minlength">wymagane jest podanie loginu</span>
          </div>
          <div class="wizard-fcn__column" v-if="supportNip && !nipNewLine">
             <div class="wizard-fcn__field">
               <input class="wizard-fcn__textinput" v-model="fields.nip" type="text" placeholder="Wpisz numer NIP" v-on:focus="ga(gacategory, 'Kliknięcie w pole NIP', 'Wpisz NIP')">
             </div>
             <div role="alert" transition="expand" class="wizard-fcn__error" v-if="submitted && ($v.fields.nip.$invalid === true)"><span>wymagany jest poprawny numer NIP</span></div>
          </div>
        </div>
        <div class="wizard-fcn__row" v-if="supportNip && nipNewLine">
          <div class="wizard-fcn__column">
            <div class="wizard-fcn__field">
              <input class="wizard-fcn__textinput" v-model="fields.nip" type="text" placeholder="Wpisz numer NIP" v-on:focus="ga(gacategory, 'Kliknięcie w pole NIP', 'Wpisz NIP')">
            </div>
            <div role="alert" transition="expand" class="wizard-fcn__error" v-if="submitted && ($v.fields.nip.$invalid === true)"><span>wymagany jest poprawny numer NIP</span></div>
          </div>
        </div>

        <!-- strefa numeracyjna -->
        <div class="wizard-fcn__row" v-if="lite">
          <div class="wizard-fcn__column">
            <multiselect :custom-label="strefaLabel" label="value" :tabindex="0" :options="strefy" v-model="fields.strefa" :searchable="true" placeholder="Wybierz strefę numeracyjną lub zacznij wpisywać numer" :max-height="140" select-label="" selected-label="Wybrana strefa" deselect-label="" track-by="value" v-on:focus="ga(gacategory, 'Kliknięcie w pole wyboru strefy', 'Wybierz strefę numeracyjną lub zacznij wpisywać numer')" v-on:input="ga(gacategory, 'Wybranie strefy', fields.strefa.l)">
              <template slot="option" slot-scope="props">
                <span v-html="highlightKeyword(props.option.l + ' (' + props.option.value + ' xxx xx xx)', props.search)"></span>
              </template>
              <template slot="noResult">
                Nie znalezionio pasujących stref.
              </template>
              <template slot="singleLabel" slot-scope="props">
                {{ props.option.l }} ({{props.option.value}} xxx xx xx)
              </template>
            </multiselect>
            <div role="alert" transition="expand" class="wizard-fcn__error" v-if="submitted && ($v.fields.strefa.$invalid === true)"><span>wymagane jest wybranie strefy numeracyjnej</span></div>
          </div>
        </div>
        <div class="wizard-fcn__row">
          <div class="wizard-fcn__column" v-if="show_more === 'yes'">
            <a  tabindex="0" v-on:click="expandCollapseChecks" v-on:keydown.enter="expandCollapseChecks" class="wizard-fcn__checks__button  wizard-fcn__checks__show_more" v-bind:class="{'wizard-fcn__checks__button--unfolded':show_checks}"><span v-show="!show_checks">POKAŻ WSZYSTKIE ZGODY</span><span v-show="show_checks">UKRYJ WSZYSTKIE ZGODY</span>
              <svg width="1em" height="1em">
                <use v-bind:xlink:href="arrow_icon" />
              </svg>
            </a>
          </div>
        </div>
        <div class="wizard-fcn__row">
          <div class="wizard-fcn__stackedrows">
            <transition name="expand">
              <div v-show="show_checks || (show_more === 'no')">
                <div :class="['wizard-fcn__row', show_more === 'yes' ? 'wizard-fcn__row--notopmargin':'']">
                  <div class="wizard-fcn__column">
                    <div :class="['wizard-fcn__checkbox', check_class]">
                      <input :id="uid + '_wizard-fcn__check1'" name="wizard-fcn__check1" v-model="check1" value="check1" type="checkbox" v-on:keyup.enter.stop.prevent="check1 = true; ga(gacategory, 'Kliknięcie zgody', 'Regulamin FCN')" v-on:click="ga(gacategory, 'Kliknięcie zgody', 'Regulamin FCN')"/>
                      <label :for="uid + '_wizard-fcn__check1'">
                          <span><span role="alert" class="wizard-fcn__error" v-if="submitted && $v.check1.$invalid">wymagana zgoda </span>Zapoznałem/am się i akceptuję treść <a href="https://fcn.pl/wp-content/uploads/2023/01/Regulamin_FCN_PrePaid_20230201.pdf" target="_blank">Regulaminu FCN</a></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="wizard-fcn__row">
                  <div class="wizard-fcn__column">
                    <div :class="['wizard-fcn__checkbox', check_class]">
                      <input :id="uid + '_wizard-fcn__check2'" v-model="check2" value="check2" type="checkbox" v-on:keydown.enter="check2 = !check2; ga(gacategory, 'Kliknięcie zgody', 'Przetwarzanie danych osobowych')" v-on:click="ga(gacategory, 'Kliknięcie zgody', 'Przetwarzanie danych osobowych')"/>
                      <label :for="uid + '_wizard-fcn__check2'">
                        <transition name="expand">
                        <span><span role="alert" class="wizard-fcn__error" v-if="submitted && $v.check2.$invalid">wymagana zgoda </span>Wyrażam zgodę na przetwarzanie danych osobowych <span v-if="checks_with_more === 'yes'" v-show="!toggles.check2">...</span><span transition="expand" v-show="toggles.check2 || checks_with_more === 'no'"> zamieszczonych w powyższym formularzu. Zgodę można w każdej chwili wycofać, poprawić lub zmienić. Wycofanie zgody nie będzie miało skutków w stosunku do danych przetwarzanych przed jej wycofaniem. </span></span>
                        </transition>
                      </label>
                      <div tabindex="0" class="wizard-fcn__checkbox__more __more_class" v-if="checks_with_more==='yes'" v-on:keydown.enter="toggle('check2')" v-on:click.stop.prevent="toggle('check2')">{{ toggledmark(toggles.check2) }}</div>
                    </div>
                  </div>
                </div>
                <div class="wizard-fcn__row">
                  <div class="wizard-fcn__column">
                    <div :class="['wizard-fcn__checkbox', check_class]">
                      <input :id="uid + '_wizard-fcn__check3'" name="wizard-fcn__check3" v-model="check3" value="check3" type="checkbox" v-on:keydown.enter="check2=!check2; ga(gacategory, 'Kliknięcie zgody', 'Odstąpienie')" v-on:click="ga(gacategory, 'Kliknięcie zgody', 'Odstąpienie')"/>
                      <label :for="uid + '_wizard-fcn__check3'" v-on:keydown.enter="check3 = !check3;">
                          <span><span role="alert" transition="expand" class="wizard-fcn__error" v-if="submitted && $v.check3.$invalid">wymagana zgoda </span>
                            Zostałem/am poinformowany/a o możliwości odstąpienia od umowy<span v-show="!toggles.check3">...</span><span transition="expand" v-show="toggles.check3  || checks_with_more === 'no'"> w terminie czternastu dni od daty jej zawarcia, składając stosowne oświadczenie w formie pisemnej, zgodnie z ustawą z dnia 30 maja 2014 r. o prawach konsumenta. Zasady odstąpienia od umowy określone są w <a href="https://fcn.pl/wp-content/uploads/2015/12/Pouczenie_o_odstapieniu_od_umowy-2021-11-01.pdf" target="_blank">pouczeniu</a>.</span></span>
                      </label>
                      <div tabindex="0" class="wizard-fcn__checkbox__more __more_class" v-if="checks_with_more==='yes'" v-on:keydown.enter="toggle('check3')" v-on:click.stop.prevent="toggle('check3')">{{ toggledmark(toggles.check3) }}</div>
                    </div>
                  </div>
                </div>

                <div class="wizard-fcn__row">
                  <div class="wizard-fcn__column">
                    <div :class="['wizard-fcn__checkbox', check_class]">
                      <input :id="uid + '_wizard-fcn__check4'" name="wizard-fcn__check4" v-model="check4" value="check4" type="checkbox" v-on:click="ga(gacategory, 'Kliknięcie zgody', 'Rozpoczęcie świadczenia usług')"/>
                      <label :for="uid + '_wizard-fcn__check4'">
                        <span><span role="alert" transition="expand" class="wizard-fcn__error" v-if="submitted && $v.check4.$invalid">wymagana zgoda </span>Oświadczam, iż zgłaszam żądanie rozpoczęcia świadczenia usług <span v-if="checks_with_more === 'yes'" v-show="!toggles.check4">...</span><span transition="expand" v-show="checks_with_more === 'no' || toggles.check4">przed upływem terminu na skorzystanie z prawa odstąpienia od zawartej umowy.</span></span>
                      </label>
                      <div tabindex="0" class="wizard-fcn__checkbox__more __more_class" v-if="checks_with_more==='yes'" v-on:keydown.enter="toggle('check4')" v-on:click.stop.prevent="toggle('check4')">{{ toggledmark(toggles.check4) }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>

      </form>
      <div class="wizard-fcn__row">
        <div class="wizard-fcn__column wizard-fcn__submit wizard-fcn__column--submit ">
          <div v-show="loading">
            <svg width="40px" height="40px">
              <use xlink:href="#hourglass" />
            </svg>
            <small role="alert">proszę czekać... trwa zakładanie konta</small>
          </div>
          <a tabindex="0" v-show="!loading" :class="['cta cta-button cta-button--red-o cta-button--bold cta-button--big']" v-on:keydown.enter="submit" aria-label="Załóż konto na platformie VoIP FCN" v-on:click="submit">ZAŁÓŻ KONTO</a>
          <transition name="expand">
            <div class="wizard-fcn__errors" v-if="$v.$error" transition="expand">
              <div role="alert" class="wizard-fcn__errors__wrapper">
                <strong>Prosimy poprawić błędy w formularzu</strong>
              </div>
            </div>
          </transition>

        </div>
      </div>
    </div>
    <div style="overflow:hidden;" v-else>
      <slot name="success" v-if="!lite">
        <slot name="success_top">
          <h2>Gratulujemy! Twoje konto FCN zostało utworzone.</h2>
          <p>
            Dane do logowania i dalsze instrukcje zostały wysłane na adres e-mail podany podczas rejestracji.
          </p>
        </slot>
        <slot name="success_number"></slot>
        <slot name="success_bottom"></slot>
      </slot>
      <slot name="success" v-if="lite">
        <slot name="success_top">
          <h2>Twoje konto FCN Lite zostało utworzone – dziękujemy za rejestrację.</h2>
        </slot>
        <slot name="success_number">
          <p>
            Twój numer telefoniczny, wylosowany z puli dla strefy wybranej w formularzu rejestracyjnym to:
            <strong class="wizard-fcn__phoneNumber">{{phoneNumber}}</strong>
          </p>
        </slot>
        <slot name="success_bottom">
          <p>
            Na adres e-mail podany w tym formularzu została wysłana wiadomość z danymi do logowania i dalszymi instrukcjami dotyczącymi uruchomienia naszej telefonii VoIP.
          </p>
        </slot>
      </slot>
    </div>
    <slot name="footer" class="footer"></slot>
  </div>
</template>
<script>
import SVGIcons from './SVGIcons'
import axios from 'axios'
// import vueSelect from 'vue-select'
// import Multiselect from 'vue-multiselect'
import { validationMixin } from 'vuelidate'
/*
import sameAs from 'vuelidate/lib/validators/sameAs'
import required from 'vuelidate/lib/validators/required'
import email from 'vuelidate/lib/validators/email'
import minLength from 'vuelidate/lib/validators/minLength'
*/
import { sameAs, required, email, minLength } from 'vuelidate/lib/validators';
import { validatePolish } from 'validate-polish';

const checked = sameAs(() => true)
/* eslint-disable */
var dataLayer = window.dataLayer || []
/* eslint-enable */

// strip spaces and "-" from value
const stripSpaces = (value) => value.replace(/ /g, '').replace(/-/g, '')


const validNIP = (value) => validatePolish.nip(value) && stripSpaces(value) !== '0000000000';

export default {
  name: 'Wizard',
  components: {
    SVGIcons,
    // resolve multiselect dynamically
    Multiselect: () => import('vue-multiselect'),
  },
  mixins: [validationMixin],
  props: {
    thankyoupage_url: {
      type: String,
      default: ''
    },
    tags: {
      type: String,
      default: ''
    },
    lite: {
      type: Boolean,
      default: false
    },
    supportNip: {
      type: Boolean,
      default: false,
    },
    nipNewLine: {
      type: Boolean,
      default: false,
    },
    test: {
      type: Boolean,
      default: false
    },
    gacategory: {
      type: String,
      default: 'Wizard2_Standard'
    },
    theme: {
      type: String,
      default: 'fcn-contrast'
    },
    'checks': {
      type: String,
      default: 'collapsed'
    },
    'checks_with_more': {
      type: String,
      default: 'yes'
    },
    'show_more': {
      type: String,
      default: 'yes'
    },
    'check_class': {
      type: String,
      default: ''
    },
    'submit_width': {
      type: String,
      default: ''
    },
    submit_align: {
      type: String,
      default: ''
    },
    'partner_id': {
      type: String,
      default: ''
    }
  },
  validations () {
    let basic = {
      fields: {
        email: {
          required,
          email
        },
        username: {
          required,
          minLength: minLength(3)
        },
      },
      check1: {
        checked
      },
      check2: {
        checked
      },
      check3: {
        checked
      },
      check4: {
        checked
      }
    };
    if (this.lite) {
      basic.fields.strefa = {
        required
      }
    }
    if (this.supportNip) {
      basic.fields.nip = {
          required,
          validNIP,
      }
    }
    return basic;
  },
  watch: {
    'fields.username': function (newValue, oldValue) {
      if (oldValue === this.suggested_username) {
        this.setSuggestedUsername('', 'username__changed')
      }
    }
  },
  data () {
    return {
      uid: '',
      msg: 'FCN Wizard (dev version)',
      stage: 0,
      phoneNumber: '',
      show_checks: false,
      arrow_icon: '#icon-chevron-down',
      opts: {
        sitekey: '6LdOThgTAAAAACKrNXa2f20yKzMCKokKjUCaA6Cz'
      },
      fields: {
        email: '',
        username: '',
        nip: '',
        strefa: '',
        captcha: false,
        checks: [],
        partner_id: '',
        tags: ''
      },
      check1: false,
      check2: false,
      check3: false,
      check4: false,
      toggles: {
        check1: false,
        check2: false,
        check3: false,
        check4: false
      },
      jwt: false,
      username_status: {
        visible: false,
        icon: '',
        text: '',
        show_suggestion: false,
        class: ''
      },
      suggested_username: '',
      submitted: false,
      loading: false,
      checking: false,
      strefy: [
        { l: 'Biała Podlaska', value: 83 },
        { l: 'Białystok', value: 85 },
        { l: 'Bielsko Biała', value: 33 },
        { l: 'Bydgoszcz', value: 52 },
        { l: 'Chełm', value: 82 },
        { l: 'Ciechanów', value: 23 },
        { l: 'Częstochowa', value: 34 },
        { l: 'Elbląg', value: 55 },
        { l: 'Gdańsk', value: 58 },
        { l: 'Gorzów', value: 95 },
        { l: 'Jelenia Góra', value: 75 },
        { l: 'Kalisz', value: 62 },
        { l: 'Katowice', value: 32 },
        { l: 'Kielce', value: 41 },
        { l: 'Konin', value: 63 },
        { l: 'Koszalin', value: 94 },
        { l: 'Kraków', value: 12 },
        { l: 'Krosno', value: 13 },
        { l: 'Legnica', value: 76 },
        { l: 'Leszno', value: 65 },
        { l: 'Lublin', value: 81 },
        { l: 'Łomża', value: 86 },
        { l: 'Łódź', value: 42 },
        { l: 'Nowy Sącz', value: 18 },
        { l: 'Olsztyn', value: 89 },
        { l: 'Opole', value: 77 },
        { l: 'Ostrołęka', value: 29 },
        { l: 'Piła', value: 67 },
        { l: 'Piotrków Trybunalski', value: 44 },
        { l: 'Płock', value: 24 },
        { l: 'Poznań', value: 61 },
        { l: 'Przemyśl', value: 16 },
        { l: 'Radom', value: 48 },
        { l: 'Rzeszów', value: 17 },
        { l: 'Siedlce', value: 25 },
        { l: 'Sieradz', value: 43 },
        { l: 'Skierniewice', value: 46 },
        { l: 'Słupsk', value: 59 },
        { l: 'Suwałki', value: 87 },
        { l: 'Szczecin', value: 91 },
        { l: 'Tarnobrzeg', value: 15 },
        { l: 'Tarnów', value: 14 },
        { l: 'Toruń', value: 56 },
        { l: 'Wałbrzych', value: 74 },
        { l: 'Warszawa', value: 22 },
        { l: 'Włocławek', value: 54 },
        { l: 'Wrocław', value: 71 },
        { l: 'Zamość', value: 84 },
        { l: 'Zielona Góra', value: 68 }
      ]
    }
  },
  created () {
    this.uid = this._uid;
    if (this.checks === 'expanded') {
      this.toggles.check1 = true
      this.toggles.check2 = true
      this.toggles.check3 = true
      this.toggles.check4 = true
    }
  },
  /*
  validators: { // `numeric` and `url` custom validator is local registration
    email: {
      check: function (val) {
        return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(val)
      }
    },
    exists: {
      check: function (val) {
        // console.log('validating ' + val + 'username')
        // return this.vm.suggestLogin()
      },
      message: 'xxx'
    }
  },
  */
  methods: {
    expandCollapseChecks () {
      this.show_checks = !this.show_checks
      if (this.show_checks) {
        this.ga(this.gacategory, 'Klikniecie w "POKAŻ WSZYSTKIE ZGODY"', '')
      } else {
        this.ga(this.gacategory, 'Klikniecie w "UKRYJ WSZYSTKIE ZGODY"', '')
      }
    },
    strefaLabel ({ l, value }) {
      return `${l} ${value}`
    },
    highlightKeyword: function (str, searchQuery) {
      if (str && searchQuery) {
        var highlight = [searchQuery.trim(), searchQuery.toLowerCase().trim()]
        str = ' ' + str
        return str.replace(new RegExp('(.)(' + highlight.join('|') + ')(.)', 'ig'), '$1<strong style="text-decoration: underline;" class="highlight">$2</strong>$3')
      } else {
        return str
      }
    },
    ga: function (category, action, label) {
      /* eslint-disable */
      // var dataLayer = dataLayer || []
      dataLayer.push({ event: 'gtmEvent', eventCategory: category, eventAction: action, eventLabel: label })
      /* eslint-enable */
    },
    thankyoupage: function () {
      this.$emit('success', true)
      if (this.thankyoupage_url) {
        /* eslint-disable */
        try {
          // var dataLayer = dataLayer || []
          dataLayer.push({
            event: 'gtmVirtualPage',
            virtualPageURL: this.thankyoupage_url,
            virtualPageTitle: ''
          })
          dataLayer.push({
            event: 'gtmConversion',
            conversionDetails: { partner_id: this.fields.partner_id, strefa: this.fields.strefa, tags: this.fields.tags.replace(' ','').split(',') }
          })
        } catch (err) {
          // console.log(err)
        }
        /* eslint-enable */
      }
    },
    onVerify: function (response) {
      this.fields.captcha = response
      this.ga(this.gacategory, 'Kliknięcie w Captcha', 'Nie jestem robotem')
    },
    onExpired: function () {
      this.fields.captcha = false
      if (this.$refs.recaptcha) {
        this.$refs.recaptcha.reset()
      }
      this.ga(this.gacategory, 'Wygaśnięcie captcha', 'Wygaśnięcie captcha')
    },
    reset: function () {
      this.$resetValidation(function () {
      })
    },
    track: function () {
    },
    onInvalid: function (stage) {
      if (this.stage === stage && this.submitted) {
        // dataLayer.push({event:'gtmEvent', eventCategory:'form', eventAction:'validation failed on field', eventLabel: x})
      }
    },
    toggle: function (elem) {
      this.toggles[elem] = !this.toggles[elem]
    },
    toggled: function (elem) {
      if (elem) {
        return '[zwiń]'
      } else {
        return '[rozwiń]'
      }
    },
    toggledmark: function (elem) {
      if (elem) {
        return '-'
      } else {
        return '+'
      }
    },
    setUsername: function (username) {
      this.fields.username = username
      this.setSuggestedUsername(username)
    },
    setSuggestedUsername: function (username, reason) {
      this.suggested_username = username
      this.username_status.show_suggestion = false
      if (this.suggested_username) {
        if (this.fields.username === this.suggested_username) {
          this.username_status.icon = '#icon-emoji-happy'
          this.username_status.class = ['wizard-fcn__ok']
          this.username_status.text = 'Login jest dostępny'
        } else {
          if (reason === 'username__minlength') {
            this.username_status.icon = '#icon-emoji-sad'
            this.username_status.class = ['wizard-fcn__error']
            this.username_status.text = 'Login jest za krótki. Minimalna długość to 3 znaki. Dozwolone znaki to małe litery (bez polskich znaków), cyfry, znaki "-" (minus) i "_" (podkreślenie), które nie mogą wystąpić na początku lub na końcu loginu.'
          } else if (reason === 'username__maxlength') {
            this.username_status.icon = '#icon-emoji-sad'
            this.username_status.class = ['wizard-fcn__error']
            this.username_status.text = 'Login jest za długi. Maksymalna długość to 26 znaków. Dozwolone znaki to małe litery (bez polskich znaków), cyfry, znaki "-" (minus) i "_" (podkreślenie), które nie mogą wystąpić na początku lub na końcu loginu.'
          } else if (this.fields.username) {
            this.username_status.icon = '#icon-emoji-sad'
            this.username_status.class = ['wizard-fcn__error']
            if (reason === 'username__not__allowed') {
              this.username_status.text = 'Nieprawidłowy format loginu. Dozwolone znaki to małe litery (bez polskich znaków), cyfry, znaki "-" (minus) i "_" (podkreślenie), które nie mogą wystąpić na początku lub na końcu loginu. Sugerowany:'
            } else {
              this.username_status.text = 'Wpisany login niedostępny, sugerowany:'
            }
            this.username_status.show_suggestion = true
          } else {
            this.username_status.icon = '#icon-bulb'
            this.username_status.class = ['wizard-fcn__suggest']
            this.username_status.text = 'Wpisz preferowany login lub wybierz sugerowany:'
            this.username_status.show_suggestion = true
          }
        }
      } else {
        if (reason === 'username__changed') {
          this.username_status.visible = false
          this.username_status.icon = ''
          this.username_status.text = ''
        } else {
          this.username_status.icon = '#icon-emoji-sad'
          this.username_status.class = ['wizard-fcn__error']
          this.username_status.text = 'Nieprawidłowy format loginu. Dozwolone znaki to małe litery (bez polskich znaków), cyfry, znaki "-" (minus) i "_" (podkreślenie), które nie mogą wystąpić na początku lub na końcu loginu.'
          if (reason === 'username__minlength') {
            this.username_status.text = 'Login jest za krótki. Minimalna długość to 3 znaki. Dozwolone znaki to małe litery (bez polskich znaków), cyfry, znaki "-" (minus) i "_" (podkreślenie), które nie mogą wystąpić na początku lub na końcu loginu.'
          }
          if (reason === 'username__maxlength') {
            this.username_status.text = 'Login jest za długi. Maksymalna długość to 26 znaków. Dozwolone znaki to małe litery (bez polskich znaków), cyfry, znaki "-" (minus) i "_" (podkreślenie), które nie mogą wystąpić na początku lub na końcu loginu.'
          }
        }
        this.username_status.show_suggestion = false
      }
    },
    suggestLogin: function () {
      this.fields.partner_id = this.partner_id
      if ((this.fields.email && this.$v.fields.email.email && this.$v.fields.email.required && this.suggested_username === '') || (this.fields.username && this.fields.username !== this.suggested_username)) {
        this.username_status.text = 'sprawdzanie dostępności loginu'
        this.username_status.icon = '#hourglass'
        this.username_status.class = ['wizard-fcn__suggest']
        this.username_status.show_suggestion = false
        this.username_status.visible = true
        axios.post('https://fcn.pl/wp-json/wizard/1.0/checklogin', this.fields).then((response) => {
          if (response.status === 200) {
            let result = response.data
            this.setSuggestedUsername(result.suggested_username, result.suggested_username_reason)
            return Promise.resolve()
          }
          this.username_status.checking = false
          return response
        }).catch((error) => {
          let result = error.data
          this.username_status.checking = false
          this.setSuggestedUsername('', result.suggested_username_reason)
          throw error
        })
      } else {
        if (this.suggested_username && !this.fields.username) {
          this.setSuggestedUsername(this.suggested_username)
        }
      }
    },
    submit: function () {
      // this.$validate(true)
      this.$v.$touch()
      this.fields.partner_id = this.partner_id
      this.fields.tags = this.tags
      if (this.test) {
        // this.loading = true
        // this.ga(this.gacategory, 'Kliknięcie w Załóż konto - powodzenie', 'Załóż konto')
        this.thankyoupage()
        this.stage = 1
        /* eslint-disable */
        // console.log(dataLayer)
        /* eslint-enable */
        return
      }
      this.submitted = true

      if (!this.$v.$invalid) {
        this.submitted = false
        this.loading = true

        this.ga(this.gacategory, 'Kliknięcie w Załóż konto - dobrze wypełnione', 'Załóż konto')

        axios.post('https://fcn.pl/wp-json/wizard/1.0/register', this.fields).then((response) => {
          this.phoneNumber = response.data.phoneNumber
          if (this.lite) {
            this.ga(this.gacategory, 'Rejestracja', 'Strefa: ' + this.fields.strefa.value + ' / ' + this.fields.strefa.l)
          } else {
            this.ga(this.gacategory, 'Kliknięcie w Załóż konto - powodzenie', 'Załóż konto')
          }
          this.thankyoupage()
          this.loading = false
          // if (this.$refs.recaptcha) {
          //  this.$refs.recaptcha.reset()
          // }
          this.stage = 1

          /*
          var smclient = response.json().smclient
          // todo: smclient cookie
          if (smclient.success) {
            $.cookie('smclient', smclient.contactId, {expires: 365 * 10, path: '/'})
          }
          */
          // todo: ga event
        }).catch((response) => {
          // if (this.$refs.recaptcha) {
          //  this.$refs.recaptcha.reset()
          // }
          this.loading = false
          if (response.data.status === 'error' && response.data.error_code === 'captcha') {
            this.ga(this.gacategory, 'Kliknięcie w Załóż konto - niepowodzenie (captcha)', 'Załóż konto')
            // self.$setValidationErrors([
            //  { field: 'captcha', message: 'reCaptcha - nie została poprawnie wypełniona' }
            // ])
          } else if (response.data.status === 'error' && response.data.error_code === 'account') {
            this.ga(this.gacategory, 'Kliknięcie w Załóż konto - niepowodzenie (login zajęty)', 'Załóż konto')
            // self.$setValidationErrors([
            //  { field: 'username', message: 'Wybrany login jest już zajęty.' }
            // ])
          } else if (response.data.status === 'error' && response.data.error_code === 'email') {
            this.ga(this.gacategory, 'Kliknięcie w Załóż konto - niepowodzenie (email blocked)', 'Załóż konto')
            // self.$setValidationErrors([
            //  { field: 'email', message: 'Adres email nieprawidłowy.' }
            // ])
          } else {
            this.ga(this.gacategory, 'Kliknięcie w Załóż konto - niepowodzenie', 'Załóż konto')
          }
        })
      } else {
        if (!this.$v.check1.required || !this.$v.check2.required || !this.$v.check3.required || !this.$v.check4.required) {
          this.show_checks = true
        }
        this.ga(this.gacategory, 'Kliknięcie w Załóż konto - błąd walidacji formularza', 'Załóż konto')
      }
    }
  },
  mounted () {
    this.$emit('mounted', true)
  }
}
</script>
<style lang="scss" scope>
  @import "~vue-multiselect/dist/vue-multiselect.min";
  @import "~breakpoint-sass/stylesheets/breakpoint";
  @import "../sass/custom_colors";
  @import "../sass/themes";
  @import "../sass/theme-helpers";
  @import "../sass/custom_text_styles";
  %focused {
    outline: 3px solid #1b541d;
    box-shadow: 0px 0px 4px White;
  }
  .wizard-fcn {
    font-family: Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    box-sizing: border-box;
    transition: all 0.3s ease;
    font-size: 16px;
    overflow: hidden;
    .loadingbox {
      position: absolute;
      z-index: 10;
      touch-action: none;
      left: 0;
      top: 0;
      right: 0;
      bottom :0;
      background: rgba(255,255,255,0.5);
    }
    form {
      position:relative;
    }
    &__row {
      display: flex;
      flex-wrap: wrap;
      margin: 0;
      // margin-top: 0 !important;
      align-items: flex-start;
      // padding: 0 2px;
      &--notopmargin {
        margin-top: 0 !important;
      }
    }
    &__column {
      flex-grow: 1;
      padding: 1em;
      padding-top: 0;
      flex-basis: 300px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      &--submit {
        flex-basis: 230px;
        flex-grow: 4;
      }
    }
    &__textinput {
      padding: 1em;
      font-size: 1em;
      box-sizing: border-box;
      text-align: center;
      display:block;
      width:100%;
      min-width:250px;
      border: 1px solid #ccc;
    }
    &__checkbox {
      font-size: 0.9em;
      position:relative;
      padding-left:2.5em;
      display:block;
      text-align: left;

      input {
        position:absolute;
        top: 0.2em;
        left: 0;
        width: 2em;
      }
      a {
        color:#369829;
      }
      label {
        cursor: pointer;
      }
    }
    &__submit {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align:center;
      flex-direction: column;
      //margin-top: em !important;
      small {
        color: #888;
        text-align:center;
        display:block;
      }
    }
    &__errors {
      font-size: 1em;
      margin-top: 1em;
      border: 1px solid red;
      &__wrapper {
        padding: 0.5em 1em!important;
        color: Red;
      }
    }
    &__stage {
    }
    &__toggle {
      color: #369829;
      transition: all 0.2s ease;
      &:hover {
        color: lighten(#369829,25%);
        transition: all 0.2s ease;
      }
    }
    &__ok {
      color: #369829;
      display: flex;
      font-size:1em;
      text-align:left;
      // padding-left: 2em;
      position: relative;
      align-items: flex-start;
      margin-top: 0.25em;
      svg {
        fill: #369829;
        // position: absolute;
        left: 0;
        min-width: 2em;
        height: 1.2em;

      }
    }
    &__error {
      display: flex;
      color: Red !important;
      font-size:1em;
      text-align: left;
      // padding-left: 2em;
      position: relative;
      align-items: flex-start;
      margin-top: 0.25em;
      svg {
        fill: Red;
        // position: absolute;
        left: 0;
        min-width: 2em;
        height: 1.2em;
      }
      a {
        text-decoration: none;
        display: inline;
        border-bottom: 1px dotted #008000;
        color: #008000;
        cursor: pointer;
        &:hover {
          color: #228822;
        }
      }
    }
    &__suggest {
      display: flex;
      align-items: flex-start;
      color: #888;
      font-size:1em;
      background: #ffffd1;
      padding: 0.5em 0em;
      padding-right: 0.5em;
      border-radius: 3px;
      text-align: left;
      margin-top: 0.25em;
      svg {
        fill: #888;
        min-width: 2em;
        height: 1.2em;
      }
      a {
        text-decoration: none;
        display: inline;
        border-bottom: 1px dotted #008000;
        color: #008000;
        cursor: pointer;
        &:hover {
          color: #228822;
        }
      }
    }
    &--width-33 {
      width:33%;
      // @include breakpoint(max-width 480px) {
      //  width:100%;
      // }
    }
    &--width-50 {
      width:50%;
      // @include breakpoint(max-width 480px) {
      //   width:100%;
      // }
      // @include breakpoint(min-width 481px, max-width 622px) {
      //  width:66%;
      // }
    }
    &--width-66 {
      width:66%;
      // @include breakpoint(max-width 620px) {
      //   width:100%;
      // }
    }
    &--width-75 {
      width:75%;
      // @include breakpoint(max-width 480px) {
      //  width:100%;
      // }
    }
    &--centered {
      margin: 0 auto;
    }
    .wizard-fcn__phoneNumber {
      display: block;
      text-align: center;
      font-size: 2rem;
    }
    .wizard-fcn__with-label {
      display:flex;
      align-items: baseline;
      flex-wrap: wrap;
      width:100%;
      label {
        display:none;
        min-width: 7em;
        padding-right: 1em;
        text-align: left;
        // @include breakpoint(min-width 480px) {
        //  text-align: right;
        // }
      }
      > div {
        width: auto !important;
        flex-grow:1;
        //flex-basis:200px;
        min-width:250px;
      }
    }
  }
  .wizard-fcn--v1 {
    .wizard-fcn__with-label {
      display:flex;
      align-items: baseline;
      width:100%;
      label {
        min-width: 7em;
        padding-right: 1em;
        text-align: right;
      }
      > div {
        width: auto !important;
        flex-grow:1;
      }
    }
  }
  .input-align--centered {
    .wizard-fcn__textinput {
      margin: 0 auto;
    }
  }
  .input-align--left {
    .wizard-fcn__textinput {
      margin-right: auto;
    }
  }
  .input-align--right {
    .wizard-fcn__textinput {
      margin-left: auto;
    }
  }

  .wizard-fcn-input--50 {
    .wizard-fcn__textinput {
      width:50%;
    }
  }
  .wizard-fcn-input--66 {
    .wizard-fcn__textinput {
      width:66%;
    }
  }
  .wizard-fcn-input--75 {
    .wizard-fcn__textinput {
      width:75%;
    }
  }

  .wizard-fcn--centered {
    .wizard-fcn__submit {
      display: flex;
      align-items: center;
    }
  }

  .recaptcha-align--center {
    .recaptcha-container > div > div {
      margin: 0 auto;
    }
    .recaptcha-container .wizard-fcn__error {
      text-align: center;
    }
  }

  .recaptcha-align--left {
    .recaptcha-container > div > div {
      margin-right: auto;
    }
    .recaptcha-container .wizard-fcn__error {
      text-align: left;
    }
  }
  .recaptcha-align--right {
    .recaptcha-container > div > div {
      margin-left: auto;
    }
    .recaptcha-container .wizard-fcn__error {
      text-align: right;
    }
  }

  .submit-align--left .wizard-fcn__submit{
    align-items:flex-start !important;
  }
  .submit-align--right .wizard-fcn__submit{
    align-items:flex-end !important;
  }
  .submit-align--centered .wizard-fcn__submit{
    align-items:center;
  }
  .submit-align--stretch .wizard-fcn__submit{
    align-items:stretch;
  }
  .submit--width-33 .cta-button {
    min-width: 33%;
    box-sizing: border-box;
  }
  .submit--width-50 .cta-button {
    min-width: 50%;
    box-sizing: border-box;
  }
  .submit--width-75 .cta-button {
    min-width: 75%;
    box-sizing: border-box;
  }
  .submit--width-100 .cta-button {
    min-width: 100%;
    box-sizing: border-box;
  }
  /* .expand-enter defines the starting state for entering */
  /* .expand-leave defines the ending state for leaving */
  .expand-enter {
    height: 0 !important;
    opacity: 0 !important;
  }
  .expand-enter-active {
    transition: all 1s ease !important;
  }

  .expand-enter-to {
    height: auto !important;
    overflow: hidden;
    opacity: 1 !important;
  }
  .expand-leave-active {
    transition: all 1s ease !important;
  }
  .expand-leave-to {
    height: 0 !important;
    opacity: 0 !important;
    overflow: hidden;
  }

  .wizard-fcn__stage--1 {
    padding-top: 3em !important;
  }
  .wizard-fcn__stage--1.expand-transition {
    transition-delay:0.3s !important;
  }

  .wizard-fcn__stage--1 {
    padding-top: 3em !important;
  }
  .wizard-fcn__stage--1.expand-transition {
    transition-delay:0.3s !important;
  }

  %wizard_box {
    position:relative;
    border: 1px solid #e8e8e8;
    padding: 1em;
    background: #f5f5f5;

    color: #aaa;
    font-weight: 400;
  }

  .wizard-fcn {
    // checkboxy stylowanie

    &__stackedrows {
      width: 100%;
    }

    &__checks__button {
      @extend %wizard_box;
      text-align: center;
      cursor: pointer;
      &--unfolded {
        transition: all 0.4s ease;
        > svg {
          transform-origin: 50%;
          transform: rotateX(180deg) translateX(0) !important;
          transition: all 0.2s ease;
        }
      }
      > svg {
        transform-origin: 50%;
        transform: rotateX(0deg) translateX(0);
        transition: all 0.4s ease;
        float: right;
      }
      /*
      &:after {
        font-family: ac-icons;
        content: 'n';
        margin-left: 1em;
        transition: .3s all ease;
        transform-origin: 50%;
        transform: rotateZ(360deg) translateX(0);
        position:absolute;
      }
      */
    }
    &__checks__show_more {
      color: #4c3b40;
    }
    &__checkbox {
      @extend %wizard_box;
      display: flex;
      padding: 0;
      .wizard-fcn__error {
        display: inline-block;
        padding-right: 0.5em;
        margin-top: 0;
      }
      &__more {
        width: auto;
        border: 1px solid #e8e8e8;
        display: flex;
        //align-self: center;
        padding: 0 0.5em;
        //align-self: stretch;
        margin-left: 0.5em;
        /* justify-content: initial; */
        background: #eaeaea;
        font-size: 2em;
        //align-items: center;
        min-width: 0.5em;
        /* text-align: center; */
        justify-content: center;
        cursor: pointer;
        align-items: center;
      }
      input {
        opacity: 0;
        float: left;
      }
      label {
        display: flex;
        align-items: center;
        padding: 1em;
        flex-grow:1;
        &:before {
          content: ' ';
          flex-shrink: 0;
          margin-right: 1em;
          margin-left: 0em;
          width:1em;
          height: 1em;
          display: block;
          background: white;
          border: 1px solid #A9A9A9;
          align-self: center;
        }
        span {
          align-self: flex-start;
        }
      }
      input[type=checkbox] + label::after {
        content: ' ';
        position: absolute;
        left: 1.05em;
        /* top: -3px; */
        width: 1em;
        height: 1em;
        display: block;
        z-index: 1;
        background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjE4MS4yIDI3MyAxNyAxNiIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAxODEuMiAyNzMgMTcgMTYiPjxwYXRoIGQ9Ik0tMzA2LjMgNTEuMmwtMTEzLTExM2MtOC42LTguNi0yNC04LjYtMzQuMyAwbC01MDYuOSA1MDYuOS0yMTIuNC0yMTIuNGMtOC42LTguNi0yNC04LjYtMzQuMyAwbC0xMTMgMTEzYy04LjYgOC42LTguNiAyNCAwIDM0LjNsMjMxLjIgMjMxLjIgMTEzIDExM2M4LjYgOC42IDI0IDguNiAzNC4zIDBsMTEzLTExMyA1MjQtNTI0YzctMTAuMyA3LTI1LjctMS42LTM2eiIvPjxwYXRoIGZpbGw9IiMzNzM3MzciIGQ9Ik0xOTcuNiAyNzcuMmwtMS42LTEuNmMtLjEtLjEtLjMtLjEtLjUgMGwtNy40IDcuNC0zLjEtMy4xYy0uMS0uMS0uMy0uMS0uNSAwbC0xLjYgMS42Yy0uMS4xLS4xLjMgMCAuNWwzLjMgMy4zIDEuNiAxLjZjLjEuMS4zLjEuNSAwbDEuNi0xLjYgNy42LTcuNmMuMy0uMS4zLS4zLjEtLjV6Ii8+PHBhdGggZD0iTTExODcuMSAxNDMuN2wtNTYuNS01Ni41Yy01LjEtNS4xLTEyLTUuMS0xNy4xIDBsLTI1My41IDI1My41LTEwNi4yLTEwNi4yYy01LjEtNS4xLTEyLTUuMS0xNy4xIDBsLTU2LjUgNTYuNWMtNS4xIDUuMS01LjEgMTIgMCAxNy4xbDExNC43IDExNC43IDU2LjUgNTYuNWM1LjEgNS4xIDEyIDUuMSAxNy4xIDBsNTYuNS01Ni41IDI2Mi0yNjJjNS4yLTMuNCA1LjItMTIgLjEtMTcuMXpNMTYzNC4xIDE2OS40bC0zNy43LTM3LjdjLTMuNC0zLjQtOC42LTMuNC0xMiAwbC0xNjkuNSAxNjkuNS03MC4yLTcxLjljLTMuNC0zLjQtOC42LTMuNC0xMiAwbC0zNy43IDM3LjdjLTMuNCAzLjQtMy40IDguNiAwIDEybDc3LjEgNzcuMSAzNy43IDM3LjdjMy40IDMuNCA4LjYgMy40IDEyIDBsMzcuNy0zNy43IDE3NC43LTE3Ni40YzEuNi0xLjcgMS42LTYuOS0uMS0xMC4zeiIvPjwvc3ZnPg==) center center no-repeat;
        transition: all .3s ease;
        transform: scale(0.6);
        opacity: 0;
        align-self: center;
      }

      input[type=checkbox]:checked + label::after {
        transform: scale(0.99);
        opacity: 1;
      }
      /* focus */
      [data-whatinput="keyboard"] & input[type=checkbox]:focus + label {
        @extend %focused;
      }
    }
    .cta-button, %cta-button {
      @include themify() {
        background: themed(mainColor);
      }
      color: white;
      padding: 1em 2em;
      text-decoration: none;
      border: 0;
      //text-transform: uppercase;
      text-align:center;
      display: inline-block;
      border-radius: 0.25em;
      cursor: pointer;
      text-shadow: none;
      &--small {
        font-size: 0.8em !important;
        padding: 0.5em 1em !important;
      }
      &--big {
        padding: 1em 2.5em !important;
        font-size: 1.3em !important;
      }
      &--upcase {
        text-transform: uppercase !important;
      }
      &--bold {
        font-weight: bold;
      }
      &--inverted {
        @include themify() {
          color: themed(mainColor);
          background: white;
        }
      }
      &--width-100 {
        min-width: 100% !important;
      }
      &--width-75 {
        min-width: 66% !important;
      }
      &--width-66 {
        min-width: 66% !important;
      }
      &--width-50 {
        min-width: 50% !important;
      }
      &--width-33 {
        min-width: 33% !important;
      }
      &--width-25 {
        min-width: 25% !important;
      }
    }
    /* custom colors */
    .cta-button {
      @each $color in $custom_style_colors {
        &--#{nth($color,1)} {
          @include themify() {
            background: themed(#{nth($color,1)}Color, #{nth($color,2)}) !important;
            color: White;
          }
        }
        &--#{nth($color,1)}-o {
          background-color: nth($color,2) !important;
        }
        &--#{nth($color,1)}-inverted {
          @include themify() {
            color: themed(#{nth($color,1)}Color, #{nth($color,2)}) !important;
            background: White;
          }
        }
      }
    }
  }
  /* buttons */

</style>
